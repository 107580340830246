<template>
  <div id="foot">
		<div class="fmenu">
			<div class="fmenu_cont">
				<ul class="fmenu_fl">
					<li>
            <router-link :to="{ name: 'content', query: {menuId: 'M0011', upMenuId:'M0004', s0:null}}" :tabindex="tabindex">{{$t('termsUse')}}</router-link>
          </li>
					<li>
            <a href="https://www.koagi.or.kr/contents/dynamicDetail?menuId=M0003&upMenuId=M0001&s0&postNo=-1" target="_blank" :tabindex="tabindex+1">{{$t('privacy')}}</a>
          </li>
					<li>
            <router-link :to="{ name: 'content', query: {menuId: 'M0015', upMenuId:'M0004', s0:null}}" :tabindex="tabindex+2">{{$t('videoInfoPolicy')}}</router-link>
          </li>
					<li>
            <a href="https://www.koagi.or.kr/contents/content?menuId=M0027&upMenuId=M0025&s0=2784#cont_title" target="_blank" :tabindex="tabindex+3">{{$t('ethicsManage')}}</a>
          </li>
					<li>
            <router-link :to="{ name: 'siteMap', query: {menuId: 'M0013', upMenuId:'M0004', s0:null}}" :tabindex="tabindex+4">{{$t('siteMap')}}</router-link>
          </li>
					<li>
            <router-link :to="{ name: 'content', query: {menuId: 'M0006', upMenuId:'M0001', s0:null}}" :tabindex="tabindex+5">{{$t('directions')}}</router-link>
          </li>
				</ul>
				<div class="farea">
					<ul class="fsns">
						<li>
              <a href="https://blog.naver.com/koagi1" target="_blank" :tabindex="tabindex+6">
                <img :alt="$t('naverB')" src="@/assets/img/skin01/img/common/f_sns_blog.png">
              </a>
            </li>
						<li>
              <a href="https://www.youtube.com/@koagi/" target="_blank" :tabindex="tabindex+7">
                <img :alt="$t('youtube')" src="@/assets/img/skin01/img/common/f_sns_youtube.png">
              </a>
            </li>
						<li>
              <a href="https://www.instagram.com/koagi_platform/" target="_blank" :tabindex="tabindex+8">
                <img :alt="$t('instagram')" src="@/assets/img/skin01/img/common/f_sns_insta.png">
              </a>
            </li>
						<li>
              <a href="https://www.facebook.com/koagiplatform" target="_blank" :tabindex="tabindex+9">
                <img :alt="$t('facebook')" src="@/assets/img/skin01/img/common/f_sns_facebook.png">
              </a>
            </li>
					</ul>
				</div>
			</div>
		</div>
		<div class="wsize">
			<div class="flogo"><img :alt="$t('nationalSj')" src="@/assets/img/skin01/img/common/f_logo.png"></div>
			<div class="finfo">
				<p>30106 세종특별자치시 연기면 수목원로 136 국립세종수목원 / TEL 044-251-0001</p>
				<p>{{$t('addrSj1')}}</p>
				<p class="tar"><img :alt="$t('nationalSj')" src="@/assets/img/skin01/img/common/foot_web_mark.png"></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
}
</script>